
.dashboardComponent {

  border: 1px solid wheat;
  padding: 24px;
}

.colorGreen {
  color: greenyellow
}

.colorRed {
  color: red
}

.color {

  color: rgb(250, 208, 131)
}

